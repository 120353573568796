import React , { useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
// import Modal from 'react-modal';

// Modal component
const ImageModalPrev = ({ imageUrl, closeModal }) => (
  <Modal
    isOpen={true} // Modal is always open, you can manage its open/close state based on your requirements
    onRequestClose={closeModal}
    contentLabel="Enlarged Image"
    className="fixed inset-0 flex flex-col-reverse sm:flex-row items-center justify-center bg-gray-800 bg-opacity-75"
    overlayClassName="fixed inset-0 z-50 overflow-auto"
    // className="modal"
    // overlayClassName="overlay"
  >
    {/* <button className='' onClick={closeModal}>Close</button> */}
    <button className="absolute top-4 right-4 m-4 text-gray-600 " onClick={closeModal}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    <img src={imageUrl} className="mx-auto my-4" />
  </Modal>
);

// Modal component
const ImageModal = ({ imageUrl, closeModal }) => (
  <Modal
    isOpen={true} // Modal is always open, you can manage its open/close state based on your requirements
    onRequestClose={closeModal}
    contentLabel="Enlarged Image"
    className="fixed inset-0 flex flex-col-reverse sm:flex-row items-center justify-center bg-gray-800 bg-opacity-75"
    overlayClassName="fixed inset-0 z-50 overflow-auto"
  >
    <div className="relative max-w-full max-h-full">
    <button className="absolute top-1 right-1 m-2 text-orange-600" onClick={closeModal}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <img src={imageUrl} alt="Enlarged Image" className="max-w-full max-h-full" />
    </div>
  </Modal>
);

function HowToDownloadCas() {
  const [selectedImage, setSelectedImage] = useState(null);
  const steps = [1,2,3,4,5]
  const paras = []
  const openModal = (imageUrl) => {
    console.log("image url:", imageUrl)
    setSelectedImage(imageUrl);
  };
  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div>
        <h1 className=' text-xl font-bold'>How to download detailed CAS statement from MF Central website</h1>
        <h2 className=' text-xl font-bold mt-4 text-left ml-4 mr-4'>What is CAS statement</h2>
        <p className='text-left ml-4 mr-4'>CAS stands for Consolidated account statement. This a statement that can be generated via 
            MF central website. It includes of all the Mutual Fund transactions made by an investor within a 
            given time period. A CAS statement helps investors to keep track of their investments and their 
            performance without having to look at individual account statements of each investment.</p>
        <h2 className=' text-xl font-bold mt-4 text-left ml-4 mr-4'>Follow these steps to download CAS statement from MF central 
        website:</h2>
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 1:</h3>
        <p className='text-left ml-4 mr-4'>Search MF Central on google.com and click on <Link to="www.mfcentral.com">www.mfcentral.com</Link></p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central google search" src="/images/step_1.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_1.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 2:</h3>
        <p className='text-left ml-4 mr-4'>Sign up or Sign in using your PAN card details.</p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central sign in" src="/images/step_2.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_2.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 3:</h3>
        <p className='text-left ml-4 mr-4'>After logging in, click on report icon on bottom left-hand side.</p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central report icon" src="/images/step_3.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_3.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 4:</h3>
        <p className='text-left ml-4 mr-4'>Click on detailed tab under Consolidated Account Statement.</p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central report icon" src="/images/step_4.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_4.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 5:</h3>
        <p className='text-left ml-4 mr-4'>Select time period and click on download as Excel button.</p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central report icon" src="/images/step_5.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_5.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 6:</h3>
        <p className='text-left ml-4 mr-4'>Go to downloads tab, refresh after a few seconds and click on download icon.</p>
        <img  className = "ml-4 mr-4 mt-2" alt="MF Central report icon" src="/images/step_6.jpg"
            style={{ "maxWidth" :'80%', "height": "auto","cursor": "pointer", "margin": '10px' }} onClick={() => openModal("/images/step_6.jpg")}/>
        
        <h3 className=' text-xl font-bold mt-2 text-left ml-4 mr-4'> Step 7:</h3>
        <p className='text-left ml-4 mr-4'>Report is downloaded in downloads folder.</p>
        
        
        {selectedImage && (
        <ImageModal imageUrl={selectedImage} closeModal={closeModal} />
      )}
    </div>
  )
}

export default HowToDownloadCas
