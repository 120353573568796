import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { createClient } from '@supabase/supabase-js';
import CategoryScrollCards from './CategoryScrollCards';
import Button from 'react-bootstrap/Button';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import FundCards from './FundCards';
import * as XLSX from 'xlsx';

const MultiFundList = () => {
  const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey);

  const [fundList, setFundList] = useState([]);
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [fundsAnalysed, setFundsAnalysed] = useState(false);
  const [greaterThan80, setGreaterThan80] = useState([]);
  const [between50And80, setBetween50And80] = useState([]);
  const [lessThan50, setLessThan50] = useState([]);
  const [indexFund, setIndexFund] = useState([]);
  const [casText, setCasText] = useState('');
  const [loading, setLoading] = useState(false);
  const [startingVal, setStartingVal] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [withdrawal, setWithdrawal] = useState(0);
  const [endingVal, setEndingVal] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [overallXirr, setOverallXirr] = useState(0);
  const [sortOption, setSortOption] = useState('investment'); // State to track the sort option
  const [fundData, setFundData] = useState(null); // State to store the fund dat
  // const [updatedFundData, setUpdatedFundData] = useState(null);
  const [analyzedFundData, setAnalyzedFundData] = useState(null);
  const [parsedExcelData, setParsedExcelData] = useState(null);
  const metricsOptions = [
    { value: 'return_1yr_perc', label: '1 Year Returns', value1: 'return_1yr_perc' },
    { value: 'return_3yr_perc', label: '3 Year Returns', value1: 'return_3yr_perc' },
    { value: 'return_5yr_perc', label: '5 Year Returns', value1: 'return_5yr_perc' },
    { value: 'sortino_1yr_perc', label: '1 year Sortino', value1: 'sortino_ratio_12_perc' },
    { value: 'sortino_3yr_perc', label: '3 year Sortino', value1: 'sortino_ratio_36_perc' },
    { value: 'sortino_5yr_perc', label: '5 year Sortino', value1: 'sortino_ratio_60_perc' },
  ];
  const [selectedMetric, setSelectedMetric] = useState(metricsOptions[2]);

  // Create a ref for the element you want to scroll to
  const resultsSectionRef = useRef(null);

  // Function to handle sorting option change
  const handleSortOptionChange = (option) => {
    setSortOption(option);
  };


  // const onDropOrig = async (acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   const reader = new FileReader();

  //   reader.onload = async () => {

  //     const text = reader.result;
  //     setCasText(text);
  //     console.log('text is', text)
  //     // You can perform additional processing with the extracted text if needed
  //   };
  //   reader.readAsText(file);
  // };

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = async () => {
      const binaryString = reader.result;

      if (file.type === 'application/pdf') {
        // Handle PDF file
        setCasText(binaryString);
        console.log('PDF file dropped:', file.name);
        console.log('PDF content:', binaryString);
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
        // Handle Excel file
        console.log('Excel file dropped:', file.name);
        const workbook = XLSX.read(binaryString, { type: 'binary' });

        // Process data from first worksheet (starting from row 12)
        const firstWorksheetName = workbook.SheetNames[0];
        const firstWorksheet = workbook.Sheets[firstWorksheetName];
        const firstWorksheetData = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1, range: 11 });

        // Process data from second worksheet (starting from row 9)
        const secondWorksheetName = workbook.SheetNames[1];
        const secondWorksheet = workbook.Sheets[secondWorksheetName];
        const secondWorksheetData = XLSX.utils.sheet_to_json(secondWorksheet, { header: 1, range: 8 });

        // Combine data from both worksheets
        const parsedData = {
          firstWorksheetData,
          secondWorksheetData
        };

        setParsedExcelData(parsedData);
        console.log('Excel data:', parsedData);
      } else {
        // Unsupported file type
        console.log('Unsupported file type:', file.type);
      }
    };

    if (file.type === 'application/pdf') {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: '.pdf, .xlsx',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  const options = fundList.map((fund) => ({
    value: fund['Scheme Code'],
    label: fund['Scheme Name Nav'],
    return_3yr_perc: fund['return_3yr_perc'],
    return_1yr_perc: fund['return_1yr_perc'],
    return_5yr_perc: fund['return_5yr_perc'],
    return_3yr: fund['return_3yr'],
    return_1yr: fund['return_1yr'],
    return_5yr: fund['return_5yr'],
    sortino_3yr_perc: fund['sortino_ratio_36_perc'],
    sortino_1yr_perc: fund['sortino_ratio_12_perc'],
    sortino_5yr_perc: fund['sortino_ratio_60_perc'],
    sortino_3yr: fund['sortino_ratio_36'],
    sortino_1yr: fund['sortino_ratio_12'],
    sortino_5yr: fund['sortino_ratio_60'],
  }));

  const handleSelectChange = (selectedOptions) => {
    setSelectedFunds(selectedOptions);
  };

  const handleMetricChange = (selectedOption) => {
    setSelectedMetric(selectedOption);
    // const analyzedGreaterThan80 = [];
    // const analyzedBetween50And80 = [];
    // const analyzedLessThan50 = [];
    // const analyzedIndexFund = [];
    // analyzeFunds(); // Trigger analysis when the metric changes
    updateCategorizations(selectedOption);
    // setFundsAnalysed(false)
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const debouncedSearch = debounce(async (query) => {
    const { data, error } = await supabase
      .from('all_funds_basic')
      .select('`Scheme Code`, `Scheme Name Nav`, `return_3yr_perc`,`return_1yr_perc`,`return_5yr_perc`,`return_3yr`,`return_1yr`,`return_5yr`,`sortino_ratio_12_perc`,`sortino_ratio_36_perc`,`sortino_ratio_60_perc`,`sortino_ratio_12`,`sortino_ratio_36`,`sortino_ratio_60`')
      .ilike('Scheme Name Nav', `%${query}%`)
      .range(0, 20);

    if (error) {
      console.error('Error fetching fund list:', error.message);
      return;
    }

    setFundList(data);
  }, 500);


  const parseNumberWithCommas = (value) => {
    if (typeof value === 'string') {
      const parsedValue = parseFloat(value.replace(/,/g, ''));
      return !isNaN(parsedValue) ? parsedValue : null;
    } else if (typeof value === 'number' && !isNaN(value)) {
      return value;
    }
    return null;
  };

  const updateCategorizations = (selectedMetric) => {
    const analyzedGreaterThan80 = [];
    const analyzedBetween50And80 = [];
    const analyzedLessThan50 = [];
    const analyzedIndexFund = [];

    analyzedFundData.forEach((fund) => {
      const metricValue = fund[selectedMetric.value];
      const fundName = fund['label'];

      if (fundName.toLowerCase().includes('index')) {
        analyzedIndexFund.push(fund);
      } else if (metricValue > 0.80) {
        analyzedGreaterThan80.push(fund);
      } else if (metricValue >= 0.50 && metricValue <= 0.80) {
        analyzedBetween50And80.push(fund);
      } else {
        analyzedLessThan50.push(fund);
      }
    });

    setGreaterThan80(analyzedGreaterThan80);
    setBetween50And80(analyzedBetween50And80);
    setLessThan50(analyzedLessThan50);
    setIndexFund(analyzedIndexFund);
  };



  const analyzeFunds = async () => {
    setLoading(true);
    const analyzedGreaterThan80 = [];
    const analyzedBetween50And80 = [];
    const analyzedLessThan50 = [];
    const analyzedIndexFund = [];
    const updatedFundData = [];

    // Create an array to store all promises
    const fetchPromises = [];

    // if (selectedFunds.length <= 1 & casText.length <= 1){
    //   setLoading(false);
    // }
    console.log('inside analyze funds')
    const selectedMetricValue = selectedMetric ? selectedMetric.value : 'return_3yr_perc';

    selectedFunds.forEach((fund) => {
      console.log('fund is', fund)
      const metricValue = fund[selectedMetricValue];
      const fundName = fund['label']

      if (fundName.toLowerCase().includes('index')) {
        analyzedIndexFund.push(fund);
      }
      else if (metricValue > 0.80) {
        analyzedGreaterThan80.push(fund);
      } else if (metricValue >= 0.50 && metricValue <= 0.80) {
        analyzedBetween50And80.push(fund);
      } else {
        analyzedLessThan50.push(fund);
      }
    });

    if (selectedFunds.length > 0) {
      console.log(' selected funds section executed')
      setFundsAnalysed(true);
      setGreaterThan80(analyzedGreaterThan80);
      setBetween50And80(analyzedBetween50And80);
      setLessThan50(analyzedLessThan50);
      setIndexFund(analyzedIndexFund)
      setAnalyzedFundData(selectedFunds);
      // setLoading(false)
    }

    // Add the promise to the array
    fetchPromises.push(
      (async () => {
        // Send PDF file to API if uploaded
        // if (casText | parsedExcelData) {
        let trueFalse = (casText !== '') | (parsedExcelData !== null)
        console.log('parsed excel data', parsedExcelData)
        console.log('trueFalse', trueFalse)
        if (trueFalse) {
          try {
            console.log('inside CAS text')
            let apiEndpoint = 'https://travelshorts.pythonanywhere.com/upload';
            // let apiEndpoint = 'http://127.0.0.1:5001/upload';
            const formData = new FormData();
            // formData.append('pdfFile', acceptedFiles[0]);
            if (casText) {
              console.log('appending pdf file')
              formData.append('pdfFile', acceptedFiles[0]);
              apiEndpoint = 'https://travelshorts.pythonanywhere.com/upload';
              // apiEndpoint = 'http://127.0.0.1:5001/upload';
            } else {
              console.log('appending excel file')
              formData.append('excelFile', acceptedFiles[0])
              // apiEndpoint = 'https://travelshorts.pythonanywhere.com/uploadExcel';
              console.log('calling excel api endpoint')
              apiEndpoint = 'https://travelshorts.pythonanywhere.com/uploadExcel';
              // apiEndpoint = 'http://127.0.0.1:5001/uploadExcel';
            }
            console.log('calling api')
            const response = await fetch(apiEndpoint, {
              method: 'POST',
              body: formData,
            });

            console.log('api response:', response)

            if (response.ok) {
              const result = await response.json();
              let result_data = result.data;
              console.log('result_data', result_data)
              setStartDate(result_data[0].start_date)
              setEndDate(result_data[0].end_date)
              setOverallXirr(result_data[0].overall_xirr)
              setFundData(result_data)
              // Process the result from the API
              for (const fund of result_data) {
                // Fetch Sortino ratios from Supabase
                const { data: supabaseData, error: supabaseError } = await supabase
                  .from('all_funds_basic')
                  .select('`Scheme Code`, `sortino_ratio_12`, `sortino_ratio_36`, `sortino_ratio_60`,`sortino_ratio_12_perc`, `sortino_ratio_36_perc`, `sortino_ratio_60_perc`')
                  .eq('Scheme Code', fund.value)
                  .single()

                if (supabaseError) {
                  console.error('Error fetching Sortino ratios from Supabase:', supabaseError.message);
                  continue; // Skip to the next fund if there's an error fetching from Supabase
                }

                const supabaseFund = supabaseData;
                const updatedFund = {
                  ...fund,
                  sortino_1yr_perc: supabaseFund.sortino_ratio_12_perc,
                  sortino_3yr_perc: supabaseFund.sortino_ratio_36_perc,
                  sortino_5yr_perc: supabaseFund.sortino_ratio_60_perc,
                  sortino_1yr: supabaseFund.sortino_ratio_12,
                  sortino_3yr: supabaseFund.sortino_ratio_36,
                  sortino_5yr: supabaseFund.sortino_ratio_60,
                };
                updatedFundData.push(updatedFund);
                // setUpdatedFundData(updatedFund);

                // let strtVal = fund.strt_val
                const startingValue = parseNumberWithCommas(fund.strt_val);
                const latestValue = parseNumberWithCommas(fund.ltst_val);
                const investmentValue = parseNumberWithCommas(fund.investment);
                const withdrawalValue = parseNumberWithCommas(fund.withdrawal);
                // console.log('strtVal', strtVal)
                // Check if starting value is a string with commas representing a number

                // setStartingVal(startingVal.current + parseFloat(strtVal))
                // setEndingVal(endingVal.current + parseFloat(fund.ltst_val.replaceAll(',', '')))
                // setInvestment(investment.current + parseFloat(fund.investment.replaceAll(',', '')))
                // setWithdrawal(withdrawal.current + parseFloat(fund.withdrawal.replaceAll(',', '')))
                // Use the parsed starting value
                setStartingVal((prevStartingVal) => prevStartingVal + startingValue);
                setEndingVal((prevEndingVal) => prevEndingVal + latestValue);
                setInvestment((prevInvestment) => prevInvestment + (investmentValue || 0));
                setWithdrawal((prevWithdrawal) => prevWithdrawal + (withdrawalValue || 0));

                const metricValue = updatedFund[selectedMetricValue];
                const fundName = updatedFund['label'];

                if (fundName.toLowerCase().includes('index')) {
                  analyzedIndexFund.push(updatedFund);
                } else if (metricValue > 0.80) {
                  analyzedGreaterThan80.push(updatedFund);
                } else if (metricValue >= 0.50 && metricValue <= 0.80) {
                  analyzedBetween50And80.push(updatedFund);
                } else {
                  analyzedLessThan50.push(updatedFund);
                }
              }
            } else {
              console.error('Error analyzing funds:', response.statusText);
            }

          } catch (error) {
            console.error('Error analyzing funds:', error.message);
          } finally {
            // Move setLoading(false) here to ensure it's only set to false after all asynchronous operations
            // setLoading(false);
            scrollToBottom();
          }
        }
      })()
    );

    // Use Promise.all to wait for all promises to complete
    Promise.all(fetchPromises).then(() => {
      console.log('inside fetch promise section')
      setLoading(false);


      if ((casText !== '') | (parsedExcelData !== null)) {
        // Set state using the state updater callback form
        // setFundsAnalysed(true, () => {
        //   // Inside this callback, you can safely assume that state has been updated
        //   setGreaterThan80(analyzedGreaterThan80);
        //   setBetween50And80(analyzedBetween50And80);
        //   setLessThan50(analyzedLessThan50);
        //   setIndexFund(analyzedIndexFund);
        //   setLoading(false);
        //   scrollToBottom();
        // });
        setGreaterThan80(analyzedGreaterThan80);
        setBetween50And80(analyzedBetween50And80);
        setLessThan50(analyzedLessThan50);
        setIndexFund(analyzedIndexFund);
        setFundsAnalysed(true);
        setAnalyzedFundData(updatedFundData);
        if (resultsSectionRef.current) {
          scrollToBottom();
        }
      } else {
        setLoading(false);
      }
    });
  };

  // Define Card component


  const AnalysisCard = ({ startDate, endDate, startingVal, investment, withdrawal, endingVal, overallXirr }) => (
    <Card Card className="mt-0 mb-3" style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', border: 'none', overflow: 'auto' }} >
      <table className='table-auto mt-3 mb-3 mx-auto border-collapse'>
        <tbody>
          <tr>
            <td className=' px-4 py-2 text-sm text-left'>Start Date</td>
            <td className='px-4 py-2 text-sm text-right text-blue-500 font-semibold'>{startDate}</td>
          </tr>
          <tr>
            <td className='border-y border-gray-200 px-4 py-2 text-sm text-left'>End Date</td>
            <td className='border-y  border-gray-200 text-blue-500 px-4 py-2 text-sm text-right font-semibold'>{endDate}</td>
          </tr>
          <tr>
            <td className='border-y border-gray-200  px-4 py-2 text-sm text-left'>Initial Amount</td>
            <td className='border-y border-gray-200 text-blue-500  px-4 py-2 text-sm text-right font-semibold'>{(startingVal).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
          </tr>
          <tr>
            <td className='border-y border-gray-200  px-4 py-2 text-sm text-left'>Investment</td>
            <td className='border-y border-gray-200 text-blue-500  px-4 py-2 text-sm text-right font-semibold'>{(investment).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
          </tr>
          <tr>
            <td className='border-y border-gray-200  px-4 py-2 text-sm text-left'>Withdrawal</td>
            <td className='border-y border-gray-200 text-blue-500 px-4 py-2 text-sm text-right font-semibold'>{(withdrawal).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
          </tr>
          <tr>
            <td className='border-y border-gray-200  px-4 py-2 text-sm text-left'>Latest Value</td>
            <td className='border-y border-gray-200 text-blue-500  px-4 py-2 text-sm text-right font-semibold'>{(endingVal).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
          </tr>
          <tr>
            <td className=' px-4 py-2 text-sm text-left'>Overall XIRR</td>
            <td className=' px-4 py-2 text-blue-500 text-sm text-right font-semibold'>{(overallXirr * 100).toFixed(1)}%</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );


  const scrollToResultsSection = () => {
    const resultsSection = document.getElementById('resultsSection');
    console.log('resultsSection', resultsSection)
    if (resultsSection) {
      resultsSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log("Results section not found!"); // Add this li
    }
  };

  const scrollToBottom = () => {
    if (resultsSectionRef.current) {
      resultsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const fetchFundList = async () => {
      const { data, error } = await supabase
        .from('all_funds_basic')
        .select('`Scheme Code`, `Scheme Name Nav`, `return_3yr_perc`,`return_1yr`,`return_5yr`,`return_3yr`,`return_1yr_perc`,`return_5yr_perc`,`sortino_ratio_12_perc`,`sortino_ratio_36_perc`,`sortino_ratio_60_perc`,`sortino_ratio_12`,`sortino_ratio_36`,`sortino_ratio_60`')
        .range(0, 20);

      if (error) {
        console.error('Error fetching fund list:', error.message);
        return;
      }

      setFundList(data);
    };

    const fetchData = async () => {
      await fetchFundList();
      // await analyzeFunds();
    };

    fetchData();

    // Updated code to use resultsSectionRef
    if (resultsSectionRef.current) {
      scrollToBottom();
    }
    // if (fundsAnalysed) {
    //   // Scroll to the results section when funds are analyzed
    //   scrollToResultsSection();
    // }
  }, [selectedMetric, fundsAnalysed, setFundsAnalysed, setGreaterThan80]);


  return (
    <div>
      <div className="text-lg font-semibold mb-4">Select Funds in your portfolio</div>
      <Select
        options={options}
        value={selectedFunds}
        onChange={handleSelectChange}
        onInputChange={handleSearchChange}
        placeholder="Type to search funds..."
        isSearchable
        isClearable
        isMulti
        className="mb-4"
      />

      <div>Or</div>
      <div className="text-lg font-semibold mb-2 mt-2">Upload detailed CAS statement</div>

      <div {...getRootProps()}

        style={{
          'text-align': 'center',
          'padding': '20px',
          'border': '1px gray dashed',
          'width': '80%',
          'height': 80,
          'margin': 'auto',
        }}
        className="dropzone mb-4 border-blue-400">
        <input {...getInputProps()} />
        <p>Drag & drop an Excel file here, or click to select one</p>
      </div>
      <aside>
        <ul className=' text-blue-400'>{files}</ul>
      </aside>

      <Button variant="outline-primary"
        onClick={analyzeFunds}
        className="mb-4 font-bold bg-slate-500"
        disabled={loading}
      >
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {' Analyzing...'}
          </>
        ) : 'Analyze'}
      </Button>

      {

        (fundsAnalysed && ((casText !== '') | (parsedExcelData !== null))) ? (
          <>
            <div className='text-lg font-semibold mb-2'> Portfolio Summary </div>
            <AnalysisCard
              startDate={startDate}
              endDate={endDate}
              startingVal={startingVal}
              investment={investment}
              withdrawal={withdrawal}
              endingVal={endingVal}
              overallXirr={overallXirr}
            />
          </>
        ):
        (null)
      }

      <div className="text-lg font-semibold mb-2 mt-4">Select the metric for categorization</div>
      <Select
        options={metricsOptions}
        value={selectedMetric}
        onChange={handleMetricChange}
        placeholder="Select metric..."
        isSearchable
        isClearable
        className="mb-2"
      />

      {fundsAnalysed && (
        <>

          <div ref={resultsSectionRef} id="resultsSection" className=" text-lg font-semibold mt-2 mb-4">Your Portfolio Funds Categorized by {selectedMetric.label}</div>
          <CategoryScrollCards
            greaterThan80={greaterThan80}
            between50And80={between50And80}
            lessThan50={lessThan50}
            indexFund={indexFund}
            selectedMetric={selectedMetric}
          />
          <div className=' mb-4'></div>
        </>
      )}

      {(fundsAnalysed && ((casText !== '') | (parsedExcelData !== null))) ? (<>

        <Row className=" d-flex justify-content-between">
          <Col className=' font-bold text-left mt-2 mb-2 ml-2'>
            Your Funds Sorted by:
          </Col>
          <Col className='text-right mb-4 mr-2 text-gray-500'>
            <select
              value={sortOption}
              onChange={(e) => handleSortOptionChange(e.target.value)}
              className="border border-gray-300 p-2 rounded-md mb-2"
            >
              <option className=' text-sm' value="investment">Investment</option>
              <option className=' text-sm' value="ltst_val">Latest Value</option>
              <option className=' text-sm' value="xirr">XIRR</option>
            </select>
          </Col>
        </Row>
        {/* <div className="text-lg font-semibold mb-2 mt-2">Your Funds Sorted by: </div> */}
        {/* Dropdown to select sort option */}
        {/* <select
          value={sortOption}
          onChange={(e) => handleSortOptionChange(e.target.value)}
          className="border border-gray-300 p-2 rounded-md mb-4"
        >
          <option value="investment">Sort by Investment</option>
          <option value="endingVal">Sort by Latest Value</option>
          <option value="xirr">Sort by XIRR</option>
        </select> */}
        {/* Pass the fund data and sort option to the FundList component */}
        <FundCards fundData={fundData} sortOption={sortOption} />
      </>
      ):
      (null)}
    </div>
  );
};

export default MultiFundList;
