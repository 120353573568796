import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { createClient } from "@supabase/supabase-js";

const FundList = () => {
  const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey);
  const [networkError, setNetworkError] = useState(false);
  const [fundList, setFundList] = useState([]);
  const [selectedFund, setSelectedFund] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchFundList = async () => {
      const { data, error } = await supabase
        .from('all_funds_basic')
        .select('`Scheme Code`, `Scheme Name`')
        .range(0, 20);
      
      console.log('data', data)

      if (error) {
        console.error('Error fetching fund list:', error.message);
        setNetworkError(true);
        return;
      }

      setFundList(data);
    };

    fetchFundList();
  }, []);

  const options = fundList.map((fund) => ({
    value: fund['Scheme Code'],
    label: fund['Scheme Name'],
  }));

  const handleSelectChange = (selectedOption) => {
    setSelectedFund(selectedOption);
  };

  const debouncedSearch = debounce(async (query) => {
    const { data, error } = await supabase
      .from('all_funds_basic')
      .select('`Scheme Code`, `Scheme Name`')
      .ilike('Scheme Name', `%${query}%`)
      .range(0, 20);

    if (error) {
      console.error('Error fetching fund list:', error.message);
      return;
    }

    setFundList(data);
  }, 500);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };

  return (
    
      <div>
      { networkError ? null : (
      <div>
      <div className="text-lg font-semibold mt-2 mb-2">Search All Funds</div>
      <Select
        options={options}
        value={selectedFund}
        onChange={handleSelectChange}
        onInputChange={handleSearchChange}
        placeholder="Type to search funds..."
        isSearchable
        isClearable
        className="mb-10"
      />
      {selectedFund && (
        <div className="mt-4 mb-20">
          <h3 className="text-lg font-semibold mb-2">Selected Fund - Press to view fund details</h3>
          <Link to={`/amc/${selectedFund.value}`}>{selectedFund.label}</Link>
        </div>
      )}
      </div>
      )}
    </div>
  );
};

export default FundList;
