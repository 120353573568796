import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';


function CategoryScrollCards(props) {

    const [selectedCategory, setSelectedCategory] = useState(null);

    const { greaterThan80, between50And80, lessThan50, indexFund, selectedMetric } = props;
    console.log('greater than 80', greaterThan80)
    console.log('between50And80', between50And80)
    console.log('less than 50', lessThan50)
    console.log('index ', indexFund)
    console.log('selected metric ', selectedMetric)

    const [catFunds, setCatFunds] = useState({});

    const toggleCategory = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    useEffect(() => {

        let currCatFunds = {}
        currCatFunds['greater than 80 percentile'] = greaterThan80
        currCatFunds['between 50 and 80 percentile'] = between50And80
        currCatFunds['less than 50 percentile'] = lessThan50
        currCatFunds['index funds'] = indexFund

        setCatFunds(currCatFunds);

    }, [setCatFunds, greaterThan80])

    const getCategoryMessage = (category) => {
        switch (category) {
            case 'greater than 80 percentile':
                return 'Funds that performed exceptionally well, ranking in the top 20% of their category.';
            case 'between 50 and 80 percentile':
                return 'Funds that performed well, ranking between the top 20% and 50% of their category.';
            case 'less than 50 percentile':
                return 'Funds that underperformed, ranking in the bottom 50% of their category.';
            case 'index funds':
                return 'Index Funds';
            default:
                return 'Unknown category';
        }
    };
    return (
        <div>

            {Object.keys(catFunds).map((category, index) => (
                <div key={category} className='m-2'>
                    {/* Filter out categories with empty arrays */}
                    {catFunds[category].length > 0 && (
                        <div
                            className={`d-flex justify-content-between align-items-center text-left text-sm font-bold clickable border-b mb-3 pb-3 `}
                            onClick={() => toggleCategory(category)}
                            style={{ cursor: 'pointer' }}
                        >
                            {/* {category !== 'index funds' ? (<span>Funds that gave {category} returns within their category</span>)
                        : (<span>Index Funds </span>)} */}
                            <span>{getCategoryMessage(category)}</span>
                            <span className="arrow-icon al">
                                {selectedCategory === category ? (
                                    <FontAwesomeIcon icon={faCaretUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faCaretDown} />
                                )}
                            </span>
                        </div>
                    )}
                    {selectedCategory === category && (
                        <Row className="row-cols-1 row-cols-md-5 gx-2">
                            {catFunds[category].map((amc) => (
                                <Col key={amc.value} className="mb-3">
                                    <Link
                                        to={`/amc/${amc['value']}`}
                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                        <Card className="mt-3" style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', border: 'none', overflow: 'auto' }}>
                                            <Card.Body>
                                                <Card.Text className='text-sm font-semibold'>{amc['label']}</Card.Text>
                                                {/* <Card.Text className='mb-1'>Return 3yr: {amc.return3yr.toFixed(2)}%</Card.Text> */}
                                                {/* <Card.Text>Return 3yr Percentile: {amc['return_3yr_perc'].toFixed(2)}</Card.Text> */}
                                                <Card.Text className=' text-sm'>
                                                    {/* {selectedMetric['value'].includes('Returns') ? (<>{selectedMetric['label']}: {(typeof amc[selectedMetric['value'].replace('_perc', '')] === 'number') ? (amc[selectedMetric['value'].replace('_perc', '')] * 100).toFixed(1) + '%' : 'NA'}</>) :
                                                        (selectedMetric['label'] + ': ' + (typeof amc[selectedMetric['value'].replace('_perc', '')] === 'number') ? (amc[selectedMetric['value'].replace('_perc', '')]).toFixed(1) + '%' : 'NA')} */}

                                                    {(selectedMetric['value'].includes('return'))?(<>{selectedMetric['label']}: {(typeof amc[selectedMetric['value'].replace('_perc', '')] === 'number') ? (amc[selectedMetric['value'].replace('_perc', '')] * 100).toFixed(1) + '%' : 'NA'}</>):
                                                    (<>{selectedMetric['label']}: {(typeof amc[selectedMetric['value'].replace('_perc', '')] === 'number') ? (amc[selectedMetric['value'].replace('_perc', '')] ).toFixed(1) : 'Not Avaialble'}</>)}

                                                    {(typeof amc[selectedMetric['value']] === 'number') ? (<div className='text-blue-600 mt-2'>performed better than {<normal className=''>{(amc[selectedMetric['value']] * 100).toFixed(0)}%</normal>} of funds in its category </div>) : null}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            ))}

        </div>
    )
}

export default CategoryScrollCards