import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const FundCards = ({ fundData, sortOption }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to parse numbers represented as strings with commas
  const parseNumberWithCommas = (value) => {
    if (typeof value === 'string') {
      const parsedValue = parseFloat(value.replace(/,/g, ''));
      return !isNaN(parsedValue) ? parsedValue : 0; // Return 0 if parsing fails
    } else if (typeof value === 'number' && !isNaN(value)) {
      return value;
    }
    return 0; // Return 0 for other cases
  };

  // Function to calculate total investment for each fund
  const calculateTotalInvestment = (fund) => {
    const startingVal = parseNumberWithCommas(fund.strt_val);
    const investment = parseNumberWithCommas(fund.investment);
    const withdrawal = parseNumberWithCommas(fund.withdrawal);
    return startingVal + investment + withdrawal;
  };

  // Function to sort funds based on the selected option
  const sortedFunds = fundData.sort((a, b) => {
    if (sortOption === 'investment') {
      return calculateTotalInvestment(b) - calculateTotalInvestment(a);
    } else {
      // Parse values before comparison
      const aValue = parseNumberWithCommas(a[sortOption]);
      const bValue = parseNumberWithCommas(b[sortOption]);
      return bValue - aValue; // Sorting in descending order
    }
  });

  return (
    <div>
      <div
        className={`d-flex justify-content-between align-items-center text-left text-sm font-bold clickable border-bottom mb-${isVisible? "2":"20"}`}
        onClick={() => setIsVisible(!isVisible)}
        style={{ cursor: 'pointer' }}
      >
        <span className='mb-2 ml-2'>
          Press to view all funds sorted by : {sortOption}
        </span>
        <span className="arrow-icon al">
          {isVisible ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </span>
      </div>
      {isVisible && (
        // Mapping through sorted funds and rendering cards
        sortedFunds.map((fund, index) => (
          <Card key={index} className="mt-3" style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', border: 'none', overflow: 'auto' }}>
            <Card.Body>
              <Card.Text className='text-sm font-semibold'>{fund.scheme}</Card.Text>
              <table className='table-auto mt-3 mb-3 mx-auto border-collapse'>
                <tbody>
                  <tr>
                    <td className=' border-gray-200 px-4 py-2 text-sm text-left font-semibold'>Latest Value</td>
                    <td className=' border-gray-200 text-blue-500 px-4 py-2 text-sm text-right'>{(parseNumberWithCommas(fund.ltst_val)).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                  </tr>
                  <tr>
                    <td className='border-y border-gray-200 px-4 py-2 text-sm text-left font-semibold'>XIRR</td>
                    <td className='border-y border-gray-200 text-blue-500 px-4 py-2 text-sm text-right'>{(fund.xirr * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%</td>
                  </tr>
                  <tr>
                    <td className='border-y border-gray-200 px-4 py-2 text-sm text-left font-semibold'>Total Investment</td>
                    <td className='border-y border-gray-200 text-blue-500 px-4 py-2 text-sm text-right'>{calculateTotalInvestment(fund).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </Card>
        ))
      )}
    </div>
  );
};

export default FundCards;
