import React from 'react';

class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <div className="mx-auto px-4">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4 text-left">At Mutual Fund Analytics, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose the information we receive from users of our website and services.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">Information We Collect</h2>
        <p className="mb-4 text-left">We collect information from users in various ways, including:</p>
        <ul className="list-disc pl-6 mb-4 text-left">
          <li>Contact information provided voluntarily by users, such as when they register for an account or contact us through our website.</li>
          <li>Automatically collected information, such as IP addresses, browser type, operating system, and other usage details when users interact with our website.</li>
          <li>Users who analyze their portfolio on our site share their CAS (Consolidated account statement) details with us. The data is temporarily stored on our server for performing analytics and providing results to the user. Post that it is deleted from the server.
            This financial information is not shared with anyone or used for any other purpose than providing protfolio insights to the user.
          </li>
        </ul>
        
        <h2 className="text-xl font-bold mb-2 text-left">How We Use Information</h2>
        <p className="mb-4 text-left">We may use the information we collect for various purposes, including:</p>
        <ul className="list-disc pl-6 mb-4 text-left">
          <li>To provide and improve our website and services.</li>
          <li>To personalize user experience and deliver targeted content and advertisements.</li>
          <li>To respond to user inquiries and provide customer support.</li>
          <li>To comply with legal obligations and enforce our Terms of Use.</li>
        </ul>
        
        <h2 className="text-xl font-bold mb-2 text-left">Disclosure of Information</h2>
        <p className="mb-4 text-left">We may disclose user information (This does not include your CAS details shared in case of portfolio analysis) to third parties in the following circumstances:</p>
        <ul className="list-disc pl-6 mb-4 text-left">
          <li>With service providers who assist us in operating our website and providing our services.</li>
          <li>In response to a subpoena, court order, or other legal process.</li>
          <li>To protect the rights, property, or safety of Mutual Fund Analytics, our users, or others.</li>
          <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
        </ul>
        
        <h2 className="text-xl font-bold mb-2 text-left">Data Security</h2>
        <p className="mb-4 text-left">We take reasonable measures to protect the security of user information, but no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">Children's Privacy</h2>
        <p className="mb-4 text-left">Our website and services are not intended for users under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete it.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">Changes to this Privacy Policy</h2>
        <p className="mb-4 text-left">We reserve the right to update or change our Privacy Policy at any time. Users are encouraged to check this page periodically for any changes. Continued use of our website and services after the posting of changes constitutes acceptance of those changes.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">Contact Us</h2>
        <p className="mb-4 text-left">If you have any questions or concerns about our Privacy Policy, please contact us at [insert contact email].</p>
        
        <p className="text-sm text-left">Last updated: 12-Mar-2024 </p>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
