import React from 'react'
import TopPerformingAMCs from './TopPerformingAMCs'
import { useEffect, useState } from "react";
import FundList from './FundList';
import { Helmet } from 'react-helmet-async';
// const supabase = createClient("https://nahsjrrpqsjysmlyweay.supabase.co", "<your-anon-key>");

function HomeScreen() {
  // console.log(process.env.REACT_APP_SUPA_API_KEY)

  return (
    <div className='m-4'>
      <Helmet>
        <title>Top Mutual Funds </title>
        <meta name="description" content="Welcome to Mutual Fund Analytics. Get data driven insights into Indian Mutual Funds.Explore top mutual funds by category. 
        You can also search individual mutual funds and click to check its performance over last 5 years compared to its peers as well as benchmark index. "></meta>
        <link rel="canonical" href="/"></link>      
      </Helmet>
      <h1 className=' text-xl font-extrabold'>Top Mutual Funds</h1>
      <p className=' text-start m-2 mb-4 text-gray-500'>Welcome to Mutual Fund Analytics. Get data driven insights into Indian Mutual Funds. Explore top mutual funds by category. 
        You can also search individual mutual funds and click to check its performance over last 5 years compared to its peers as well as benchmark index.</p>
      {/* <div>This is home Screen Baby</div> */}
      <TopPerformingAMCs/>

      <FundList/>
    </div>
  )
}

export default HomeScreen