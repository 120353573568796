import '../index.css'
import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import Account from './Account'

// const supabase = createClient('https://<project>.supabase.co', '<your-anon-key>')

const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export default function LoginScreen() {
    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    if (!session) {
        return (
            <div className="auth-container">
                <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={['google']} />
            </div>
            )
  }
    else {
        return (<>
        <Account key={session.user.id} session={session} />
        {/* <div>Logged in!</div>
        <button onClick={() => supabase.auth.signOut()}>Sign out</button> */}
        </>)
    }
}