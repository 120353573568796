// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCrJAlYRzLHv0YyHJRXIdqq_MUA4qs4khY",
  authDomain: "mf-analysis-2023.firebaseapp.com",
  projectId: "mf-analysis-2023",
  storageBucket: "mf-analysis-2023.appspot.com",
  messagingSenderId: "1015996698888",
  appId: "1:1015996698888:web:9196ed13ab6e9d4aad1cbd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;