// import React from 'react'
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createClient } from "@supabase/supabase-js";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

function BestInCategory({ category }) {

    const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
    const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
    const supabase = createClient(supabaseUrl, supabaseKey)
    const [networkError, setNetworkError] = useState(false);
    const [topAMCs, setTopAMCs] = useState([]);
    const [sortBy, setSortBy] = useState('return_3yr');
    const [yrs, setYrs] = useState(3);
    const [name, setName] = useState('Return');

    // download data for the category from supabase using use effect
    // use the data to render the best in category
    useEffect(() => {
        const fetchTopAMCs = async () => {
            // const categories = ['Category1', 'Category2', 'Category3']; // Replace with your actual categories

            const { data, error } = await supabase
                .from("all_funds_basic")
                .select()
                .eq('Scheme Category', category)
                .lt(`${sortBy}_perc`, 2)
                .order(sortBy, { ascending: false })
                .limit(30);

            if (error) {
                console.error('Error fetching data:', error.message);
                setNetworkError(true);
                return [];
            }

            // Use a Set to ensure unique Scheme Names and store the top 5 rows
            const uniqueTopAMCsSet = new Set();
            const uniqueTopAMCs = [];

            // Iterate through the data and update the Set
            data.forEach((item) => {
                const schemeName = item['Scheme Name'];
                const returnPercentile = item['return_3yr_perc'];

                // Update the Set if the schemeName is not present or if the return percentile is higher
                if (!uniqueTopAMCsSet.has(schemeName)) {
                    uniqueTopAMCsSet.add(schemeName);
                    uniqueTopAMCs.push(item);
                }

                // Break the loop when 5 unique scheme names are found for the category
                if (uniqueTopAMCs.length >= 5) {
                    return;
                }
            });

            console.log('category:', category);
            console.log('data:', uniqueTopAMCs);

            // let categ_dict = {}
            // categ_dict['category'] = categ
            // categ_dict[categ] = uniqueTopAMCs

            // Limit to top 5 AMCs
            const limitedTopAMCs = uniqueTopAMCs.slice(0, 5);
            console.log(limitedTopAMCs)

            return limitedTopAMCs;
        };

        const getTopAMCs = async () => {
            const topAMCs = await fetchTopAMCs();
            setTopAMCs(topAMCs);
        };

        getTopAMCs();
    }, [category, sortBy]);

    const handleSortChange = (value) => {
        let yrs = null
        let name = null
        if (value.includes('sortino')) {
            // Extract the last two characters
            var lastTwoCharacters = value.substring(value.length - 2);

            // Convert the substring to an integer
            var resultAsInteger = parseInt(lastTwoCharacters);
            yrs = resultAsInteger / 12
            name = 'Sortino'
        }
        else {

            yrs = parseInt(value.charAt(value.length - 3))
            name = 'Return'
        }
        setName(name);
        setYrs(yrs);
        setSortBy(value);
    };

    const isValidNumber = (value) => typeof value === 'number' && !isNaN(value);

    return (
        <Container fluid>
            <Row className=" d-flex justify-content-between">
                <Col sx={7} className='text-left mb-4 text-gray-500'>
                    Sort by:
                </Col>
                <Col xs={7} className='text-right mb-4 mr-2 text-gray-500'>
                    <select className='' value={sortBy} onChange={(e) => handleSortChange(e.target.value)}>
                        <option value="return_3yr">Annualized Returns 3yr</option>
                        <option value="return_1yr">Annualized Returns 1yr</option>
                        <option value="return_5yr">Annullized Returns 5yr</option>
                        <option value="sortino_ratio_36">Risk Adjusted Returns 3yr</option>
                        <option value="sortino_ratio_12">Risk Adjusted Returns 1yr</option>
                        <option value="sortino_ratio_60">Risk Adjusted Returns 5yr</option>
                    </select>
                </Col>
            </Row>
            {/* <div className='text-left mb-4 text-gray-500'>Press to view top funds within the category</div> */}
            {networkError ? (
                <div className=' mt-20 font-bold text-red-800'>Network error occurred. Please check your network connection and try again.</div>
            ) : (
                <Row className="row-cols-1 row-cols-md-5 gx-2">
                    {/* <div>{topAMCs[0]}</div> */}
                    {(topAMCs.map((amc) => (
                        <Col key={amc.Code} className="mb-3">
                            <Link
                                to={`/amc/${amc['Scheme Code']}`}
                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Card className="mt-3 " style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', border: 'none', overflow: 'auto', backgroundColor: 'skin' }}>
                                    <Card.Body>
                                        <Card.Text className=' text-sm font-semibold'>{amc['Scheme Name']}</Card.Text>
                                        <Card.Text className='mb-1 text-sm'>
                                            {/* Return {yrs}yr: {isValidNumber(amc[sortBy]) ? (amc[sortBy] * 100).toFixed(1) : 'N/A'}% */}
                                            {name} {yrs}yr: {isValidNumber(amc[sortBy]) ?
                                                sortBy.includes('sortino') ? <normal>{amc[sortBy].toFixed(2)}</normal> : <normal>{(amc[sortBy] * 100).toFixed(1) + '%'}</normal>
                                                : 'N/A'}
                                        </Card.Text>
                                        {/* <Card.Text className='text-sm'>
                                            {name} {yrs}yr Percentile: {isValidNumber(amc[`${sortBy}_perc`]) ? <normal>{(amc[`${sortBy}_perc`] * 100).toFixed(0)}</normal> : 'N/A'}
                                        </Card.Text> */}
                                        {isValidNumber(amc[`${sortBy}_perc`]) ?
                                            (<Card.Text className='text-sm text-blue-600 mt-2'>
                                                performed better than {<normal>{(amc[`${sortBy}_perc`] * 100).toFixed(0)}</normal>}% of funds in its category
                                            </Card.Text>) : null}
                                        {/* <Card.Text className='mb-1'>Return 3yr: {amc.return_3yr.toFixed(2) * 100}%</Card.Text>
                        <Card.Text>Return 3yr Percentile: {amc.return_3yr_perc.toFixed(2) * 100}</Card.Text> */}
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    )))
                        // (<></>)
                    }
                </Row>

            )

            }


        </Container>
    )
}

export default BestInCategory