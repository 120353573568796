import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBarCustom from './components/NavBarCustom';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import PortfolioAnalysisScreen from './components/PortfolioAnalysisScreen';
import HomeScreen from './components/HomeScreen';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite'
import app from './FireBase';
import AMCScreen from './components/AMCScreen';
import HowToDownloadCas from './components/HowToDownloadCas';
import TermsOfUsePage from './components/TermsOfUsePage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import StrategyScreen from './components/StrategyScreen';
import LoginScreen from './components/LoginScreen';

const db = getFirestore(app);

// import { NavbarOffcanvas } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <Router>
      <NavBarCustom/>
      <Routes>
          <Route path = "/PortfolioAnalysis" element={<PortfolioAnalysisScreen/>}></Route>
          <Route path = "/HowToDownloadCas" element={<HowToDownloadCas/>}></Route>
          <Route path = "/TermsOFUse" element={<TermsOfUsePage/>}></Route>
          <Route path = "/PrivacyPolicy" element={<PrivacyPolicyPage/>}></Route>
          <Route path = "/amc/:amcCode" element={<AMCScreen/>}></Route>
          <Route path = "/strategy" element={<StrategyScreen/>}></Route>
          <Route path = "/login" element={<LoginScreen/>}></Route>
          <Route path = "/" element={<HomeScreen/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
