import React from 'react'
import MultiFundList from './MultiFundList'
import { Helmet } from 'react-helmet-async'
import { Line } from 'react-chartjs-2'
import { Link } from 'react-router-dom'


function PortfolioAnalysisScreen() {
  return (
    <div className='m-4 mb-12'>
      <Helmet>
        <title>Mutual Fund Portfolio Analysis</title>
        <meta name="description" content="Get insights into your mutual fund portfolio. You can either select the funds in your portfolio or 
        upload detailed CAS(consolidated accout statement) downloaded from MF Central. "></meta>
        <link rel="canonical" href="/PortfolioAnalysis"></link>
      </Helmet>
      <h1 className=' text-xl font-bold'>Mutual Fund Portfolio Analysis</h1>
      <p className=' text-start m-2 mb-4 text-gray-500'>Get insights into your mutual fund portfolio. You can either select the funds in your portfolio or
        upload detailed CAS(consolidated accout statement) downloaded from MF Central. 
         <Link to={`/HowToDownloadCas`}>Click here</Link> to view guide on how to download CAS statement</p>
      {/* <div>PortfolioAnalysis</div> */}
      <MultiFundList />
    </div>
  )
}

export default PortfolioAnalysisScreen