import React from 'react';

class TermsOfUsePage extends React.Component {
  render() {
    return (
      <div className="mx-auto px-4">
        <h1 className="text-3xl font-bold mb-4">Terms of Use</h1>
        <p className="mb-4 text-left">Welcome to Mutual Fund Analytics! These Terms of Use govern your access to and use of our website and services. By accessing or using our website and services, you agree to be bound by these Terms of Use.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">1. Overview</h2>
        <p className="mb-4 text-left">Mutual Fund Analytics is a platform that utilizes public data sources to download information about mutual funds and generates insights based on the data. Our services are provided on an automated basis and may contain inaccuracies or errors. Users should use the insights provided by Mutual Fund Analytics as a reference only and not as the sole basis for making investment decisions.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">2. Accuracy of Information</h2>
        <p className="mb-4 text-left">While we strive to provide accurate and up-to-date information, Mutual Fund Analytics cannot guarantee the accuracy, completeness, or reliability of the data obtained from public sources. Users acknowledge that the information provided on our website may be subject to errors, omissions, or inaccuracies.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">3. No Financial Advice</h2>
        <p className="mb-4 text-left">The insights generated by Mutual Fund Analytics are not intended to constitute financial advice. Users should consult with a qualified financial advisor or conduct their own independent research before making investment decisions. Mutual Fund Analytics does not provide personalized investment advice or recommendations.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">4. User Responsibilities</h2>
        <p className="mb-4 text-left">Users are solely responsible for their use of Mutual Fund Analytics and any decisions made based on the insights provided. Users agree to use our website and services in accordance with these Terms of Use and applicable laws and regulations.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">5. Limitation of Liability</h2>
        <p className="mb-4 text-left">Mutual Fund Analytics and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from:</p>
        <ul className="list-disc pl-6 mb-4 text-left">
          <li>The use or inability to use our website or services.</li>
          <li>The accuracy or completeness of the information provided.</li>
          <li>Any unauthorized access to or alteration of your data.</li>
        </ul>
        
        <h2 className="text-xl font-bold mb-2 text-left">6. Indemnification</h2>
        <p className="mb-4 text-left">Users agree to indemnify, defend, and hold harmless Mutual Fund Analytics and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from or related to:</p>
        <ul className="list-disc pl-6 mb-4 text-left">
          <li>User's violation of these Terms of Use.</li>
          <li>User's use of our website or services.</li>
          <li>User's violation of any rights of any other person or entity.</li>
        </ul>
        
        <h2 className="text-xl font-bold mb-2 text-left">7. Changes to Terms of Use</h2>
        <p className="mb-4 text-left">Mutual Fund Analytics reserves the right to modify or update these Terms of Use at any time without prior notice. Users are encouraged to review these Terms of Use periodically for any changes. Continued use of our website and services after any modifications to these Terms of Use shall constitute your acceptance of such changes.</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">8. Governing Law</h2>
        <p className="mb-4 text-left">These Terms of Use shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any disputes arising out of or relating to these Terms of Use shall be subject to the exclusive jurisdiction of the courts in [insert jurisdiction].</p>
        
        <h2 className="text-xl font-bold mb-2 text-left">Contact Us</h2>
        <p className="mb-4 text-left">If you have any questions or concerns about these Terms of Use, please contact us at [insert contact email].</p>
        
        <p className="text-sm text-left">Last updated: 27-Feb-2024</p>
      </div>
    );
  }
}

export default TermsOfUsePage;
