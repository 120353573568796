import { useState, useEffect } from 'react';
import { createClient } from "@supabase/supabase-js";

function StrategyScreen() {
  const [formData, setFormData] = useState({
    index1_name: '',
    index2_name: '',
    sip_amount: '',
    index1_allocation: '',
    upper_limit_hit_index1_allocation: '',
    lower_limit_hit_index1_allocation: '',
    lower_rebalance_threshold: '',
    upper_rebalance_threshold: '',
    rebalance_index: ''
  });

  const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey);

  const [indexNames, setIndexNames] = useState([]);

  useEffect(() => {
    const fetchIndexNames = async () => {
      // const { data, error } = await supabase
      //   .from("index_hist_data")
      //   .select("INDEX_NAME", {
      //     distinct: true
      //   })
      //   .order("INDEX_NAME", { ascending: true })
        // .execute();
      
      const { data, error } = await supabase.rpc('get_distinct_column_values',{'table_name':'index_hist_data',
                                                                  'column_name':'INDEX_NAME'})
    console.log("data", data);


      if (error) {
        console.error('Error fetching index names:', error);
      } else {
        const uniqueIndexNames = [...new Set(data.map((row) => row['column_value']))];
        setIndexNames(uniqueIndexNames);
      }
    };


    fetchIndexNames();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <div className="mb-4">
        <select id="index1_name" name="index1_name" value={formData.index1_name} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2">
          <option value="">Select Index 1 Name</option>
          {indexNames.map((name) => (
            <option key={name} value={name}>{name}</option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select id="index2_name" name="index2_name" value={formData.index2_name} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2">
          <option value="">Select Index 2 Name</option>
          {indexNames.map((name) => (
            <option key={name} value={name}>{name}</option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <input id="sip_amount" type="text" name="sip_amount" value={formData.sip_amount} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="SIP Amount" />
      </div>
      <div className="mb-4">
        <input id="index1_allocation" type="text" name="index1_allocation" value={formData.index1_allocation} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Index 1 Allocation" />
      </div>
      <div className="mb-4">
        <input id="upper_limit_hit_index1_allocation" type="text" name="upper_limit_hit_index1_allocation" value={formData.upper_limit_hit_index1_allocation} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Upper Limit Hit Index 1 Allocation" />
      </div>
      <div className="mb-4">
        <input id="lower_limit_hit_index1_allocation" type="text" name="lower_limit_hit_index1_allocation" value={formData.lower_limit_hit_index1_allocation} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Lower Limit Hit Index 1 Allocation" />
      </div>
      <div className="mb-4">
        <input id="lower_rebalance_threshold" type="text" name="lower_rebalance_threshold" value={formData.lower_rebalance_threshold} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Lower Rebalance Threshold" />
      </div>
      <div className="mb-4">
        <input id="upper_rebalance_threshold" type="text" name="upper_rebalance_threshold" value={formData.upper_rebalance_threshold} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Upper Rebalance Threshold" />
      </div>
      <div className="mb-4">
        <input id="rebalance_index" type="text" name="rebalance_index" value={formData.rebalance_index} onChange={handleChange} className="border border-gray-300 rounded px-3 py-2" placeholder="Rebalance Index" />
      </div>
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
    </form>
  );
}

export default StrategyScreen