import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import { Line } from 'react-chartjs-2';
import { TimeScale, Chart, registerables } from "chart.js";
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter
import { type } from '@testing-library/user-event/dist/type';
import { Helmet } from 'react-helmet-async';
import BestInCategory from './BestInCategory';
Chart.register(TimeScale, ...registerables);

const AMCScreen = () => {
  const supabaseUrl = 'https://nahsjrrpqsjysmlyweay.supabase.co';
  const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey);
  const { amcCode } = useParams();
  const [schemeName, setSchemeName] = useState();
  const [navData, setNavData] = useState();
  const [fundData, setFundData] = useState();
  const [indexReturns, setIndexReturns] = useState();
  const [networkError, setNetworkError] = useState(false);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const schemeData = await supabase.from("all_funds_basic").select().eq('Scheme Code', amcCode);
      if (schemeData.data !== null){
      if (schemeData.data.length > 0) {
        setSchemeName(schemeData.data[0]['Scheme Name']);
        const schemeCategory = schemeData.data[0]['Scheme Category']
        console.log('scheme category', schemeCategory)
        setCategory(schemeCategory)
        const benchMarkIndexData = await supabase.from("category_index_mapping").select().eq("basic_categories", schemeCategory)
        if (benchMarkIndexData.data.length > 0) {
          const schemeIndex = benchMarkIndexData.data[0]['index_name_match'].toUpperCase()
          console.log('scheme index is', schemeIndex)
          const indexReturnsData = await supabase.from("index_returns").select().eq("Index Name Upper", schemeIndex)
          if (indexReturnsData.data.length > 0) {
            const indexReturns1 = indexReturnsData.data[0]
            console.log('indexReturns::', indexReturns1)
            setIndexReturns(indexReturns1);
          }
        }
        setFundData(schemeData.data[0]);
      } 
    } else {
        setNetworkError(true);
    }

      const navItems = await supabase.from("fund_navs").select().eq('Scheme Code', amcCode).order('Date', { ascending: true });
      if (navItems.data.length > 0) {
        setNavData(navItems.data);
      }
    };

    fetchData();
  }, [amcCode]);

  return (
    <div className='m-4'>
      <Helmet>
        <title>Fund Details</title>
        <meta name="description" content="Performance analysis of mutual fund over last 5 years. 
        Check fund's performance compared to the peers within same category. For equity funds also get a view of fund's performance compared to benchmark index. "></meta>
        <link rel="canonical" href="/amc/{amcCode}"></link>      
      </Helmet>
      <h1 className=' text-xl font-extrabold'>Fund Details</h1>
      <p className=' text-start m-2 mb-4 text-gray-500'>Performance analysis of mutual fund over last 5 years. 
        Check fund's performance compared to the peers within same category. For equity funds also get a view of fund's performance compared to benchmark index.</p>
      { networkError ? (<div> Network issue, please check your connection </div>) :
      
      (schemeName ? (
        <div className='font-semibold'>{schemeName}</div>
      ) : (
        <div className='font-semibold'>Scheme Not Found</div>
      ))}

      {navData && navData.length > 0 ? (
        <div>
          <div className='mt-3 text-sm'>NAV History</div>
          <div>
            {/* Use the Line component from react-chartjs-2 directly */}
            <Line
              data={{
                labels: navData.map(entry => new Date(entry.Date)),
                datasets: [
                  {
                    label: 'NAV',
                    data: navData.map(entry => entry.NAV),
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    borderColor: 'rgba(75,192,192,1)',
                    spanGaps: true,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false, // Set to false to adjust width
                layout: {
                  padding: {
                    left: 20,  // Adjust the left padding as needed
                    right: 20, // Adjust the right padding as needed
                  },
                },
                scales: {
                  x: {
                    type: 'time',
                    time: {
                      unit: 'month',
                      tooltipFormat: 'MMM yyyy', // Format for tooltip
                    },
                    title: {
                      display: false,
                      text: 'Date',
                    },
                    grid: {
                      drawBorder: false,
                      display: false, // Hide x-axis grid lines
                    },
                  },
                  y: {
                    title: {
                      display: false,
                      text: 'NAV',
                    },
                    grid: {
                      display: false, // Hide y-axis grid lines
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                    labels: {
                      usePointStyle: true, // Show a colored line instead of a box
                    },
                  },
                },
                interaction: {
                  intersect: false,
                  mode: 'index',
                  axis: 'x', // Display tooltips on the x-axis
                },
                tooltips: {
                  callbacks: {
                    label: (context) => {
                      const label = context.dataset.label || '';
                      if (label) {
                        return `${label}: ${context.parsed.y}`;
                      }
                      return null;
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      ) : (
        <div> </div>
      )}

      {fundData ? (
        <div>
          <div className='mt-5 mb-3 '>Returns and Percentiles</div>
          <table className='table-auto mt-3 mx-auto border-collapse'>
            <thead>
              <tr>
                <th className=' border-y text-sm px-4 py-2 text-sm'>Time Period (yrs)</th>
                <th className='border-y text-sm px-4 py-2 text-sm'>Return</th>
                <th className=' border-y text-sm px-4 py-2 text-sm'>Return Percentile</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>1</td>
                {/* <td className='border-y px-4 py-2 text-sm'>{fundData.return_1yr.toFixed(2)}</td>
                <td className='border-y px-4 py-2 text-sm'>{fundData.return_1yr_perc.toFixed(2)}</td> */}
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_1yr === 'number' ? (fundData.return_1yr*100).toFixed(1) : 'NA'}%
                </td>
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_1yr_perc === 'number' ? (fundData.return_1yr_perc*100).toFixed(0) : 'NA'}
                </td>
              </tr>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>3</td>
                {/* <td className='border-y px-4 py-2 text-sm'>{fundData.return_3yr.toFixed(2)}</td>
                <td className='border-y px-4 py-2 text-sm'>{fundData.return_3yr_perc.toFixed(2)}</td> */}
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_3yr === 'number' ? (fundData.return_3yr*100).toFixed(1) : 'NA'}%
                </td>
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_3yr_perc === 'number' ? (fundData.return_3yr_perc*100).toFixed(0) : 'NA'}
                </td>
              </tr>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>5</td>
                {/* <td className='border-y px-4 py-2 text-sm'>{fundData.return_5yr.toFixed(2)}</td>
                <td className='border-y px-4 py-2 text-sm'>{fundData.return_5yr_perc.toFixed(2)}</td> */}
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_5yr === 'number' ? (fundData.return_5yr*100).toFixed(1) : 'NA'}%
                </td>
                <td className='border-y px-4 py-2 text-sm'>
                  {typeof fundData.return_5yr_perc === 'number' ? (fundData.return_5yr_perc*100).toFixed(0) : 'NA'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div></div>
      )}

      {indexReturns ? (

        <div>
          <div className='mt-5 mb-3 '>Benchmark Index Information</div>
          <table className='table-auto mt-3 mx-auto border-collapse'>
            <tbody>
              <tr>
                <td className='border-y px-4 py-2 text-sm font-bold'>Index Name</td>
                <td className='border-y px-4 py-2 text-sm font-bold'>{indexReturns['Index Name Upper']}</td>
              </tr>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>1-Year Return</td>
                <td className='border-y px-4 py-2 text-sm'>{(indexReturns["return_1yr"]*100).toFixed(1)}%</td>
              </tr>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>3-Year Return</td>
                <td className='border-y px-4 py-2 text-sm'>{(indexReturns['return_3yr']*100).toFixed(1)}%</td>
              </tr>
              <tr>
                <td className='border-y px-4 py-2 text-sm'>5-Year Return</td>
                <td className='border-y px-4 py-2 text-sm'>{(indexReturns['return_5yr']*100).toFixed(1)}%</td>
              </tr>
            </tbody>
          </table>
        </div>

      ) :

        (
          <div></div>
        )
      }

      {category ? (
      <>
      <div className='mt-5 mb-3 '>Best Funds in Category</div>
      <BestInCategory 
      category ={category} />
    </>) :
    (<div></div>)}
  </div>
  );
};

export default AMCScreen;
